import React from "react";
import { useTranslation } from "react-i18next";
import { DataRowSubType, DataRowType } from "../../../../../core/enums/enums";
import { IDataRow, IDataRowProps } from "../../../../../core/interfaces/document";
import ActionPlanDataRow from "./DataRows/ActionPlanDataRow";
import AssessmentScopeSeverity from "./DataRows/AssessmentScopeSeverity";
import DateDataRow from "./DataRows/DateDataRow";
import FileDataRow from "./DataRows/FileDataRow/FileDataRow";
import ImageDataRow from "./DataRows/ImageDataRow";
import MultiChoiceDataRow from "./DataRows/MultiChoiceDataRow";
import MultiPickDataRow from "./DataRows/MultiPickDataRow";
import MultiUserDataRow from "./DataRows/MultiUserDataRow";
import NumericDataRow from "./DataRows/NumericDataRow";
import RiskAssessmentDataRow from "./DataRows/RiskAssessmentDataRow";
import SignatureDataRow from "./DataRows/SignatureDataRow";
import TextFieldDataRow from "./DataRows/TextFieldDataRow";
import UserAndDateDataRow from "./DataRows/UserAndDateDataRow";
import UserDataRow from "./DataRows/UserDataRow";


interface IGeneralizedBaseDataRow {
    handleUpdateDataRow: (dataRow: IDataRow, index: number) => void;
    handleRemoveDataRow: (dataRow: IDataRow) => void;
    handleCopyDataRow: (dataRow: IDataRow) => void;
    checklistViewTable?: boolean;
    isActiveMode?: boolean;
    isEditMode?: boolean;

    isAnswerMode?: boolean;

    forceHeaderDisabled?: boolean;

    dataRowWrapper?: ((dataRow: IDataRow, renderedDataRow: React.ElementType<IDataRowProps<any>>, index: number, props: IDataRowProps<any>) => JSX.Element);
    isSaveDisabled?: boolean;
    setSaveDisabled?: (value: boolean) => void;
    requiredSubType?: DataRowSubType[];
    requiredType?: DataRowType[];
    moduleId?: string;
    formId?: string;
    delayedUpdate?: boolean;

    isFromActionPlan?: boolean;
    actionPlanId?: string;
    isFromReportForm?: boolean;
}

interface IGeneralizedDataRow extends IGeneralizedBaseDataRow {
    dataRows: IDataRow[];

}

const DataRowMap = new Map<DataRowType, React.FC<IDataRowProps<any>>>([
        [DataRowType.TEXT_FIELD, TextFieldDataRow],
        [DataRowType.NUMERIC_FIELD, NumericDataRow],
        [DataRowType.SIGNATURE_FIELD, SignatureDataRow],
        [DataRowType.RISK_ASSESSMENT_FIELD, RiskAssessmentDataRow],
        [DataRowType.MULTI_CHOICE_FIELD, MultiChoiceDataRow],
        [DataRowType.ACTION_PLAN_FIELD, ActionPlanDataRow],
        [DataRowType.DATE_FIELD, DateDataRow],
        [DataRowType.MULTI_USER_FIELD, MultiUserDataRow],
        [DataRowType.MULTI_PICK_FIELD, MultiPickDataRow],
        [DataRowType.IMAGE_FIELD, ImageDataRow],
        [DataRowType.USER_FIELD, UserDataRow],
        [DataRowType.FILES_FIELD, FileDataRow],
        [DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD, AssessmentScopeSeverity],
        [DataRowType.USER_AND_DATE_FIELD, UserAndDateDataRow],
    ]
);

interface IRenderDataRow extends IGeneralizedBaseDataRow {
    dataRow: IDataRow;
    index: number;
}

const RenderDataRow: React.FC<IRenderDataRow> = (
    {
        dataRow,
        index,
        handleUpdateDataRow,
        handleRemoveDataRow,
        handleCopyDataRow,
        isActiveMode = false,
        isEditMode = false,
        isAnswerMode = false,
        checklistViewTable = false,
        forceHeaderDisabled = false,
        dataRowWrapper,
        isSaveDisabled = false,
        setSaveDisabled = (v: boolean) => NaN,
        requiredSubType = [],
        requiredType = [],
        formId = "",
        moduleId = "",
        isFromActionPlan,
        actionPlanId,
        isFromReportForm
    }) => {

    const {t} = useTranslation();
    const DataRow = DataRowMap.get(dataRow.checklistFormDataRowTypeSymbol);

    const handleUpdate = (dataRow: IDataRow, index: number) => {
        handleUpdateDataRow(dataRow, index);
    }

    const props = {
        isEditMode: isEditMode,
        key: dataRow.id,
        moduleId,
        formId,
        dataRow: dataRow,
        setDataRow: (dataRow) => handleUpdate(dataRow, index),
        isHeaderEnabled: !dataRow.isClosed && isEditMode && isActiveMode && !forceHeaderDisabled,
        isFieldEnabled: !dataRow.isClosed && ((isEditMode && isActiveMode) || isAnswerMode),
        removeDataRow: () => {
            return handleRemoveDataRow(dataRow)
        },
        copyDataRow: () => {
            return handleCopyDataRow(dataRow)
        },
        isActive: isActiveMode,
        isAnswerMode: isAnswerMode,
        isFromActionPlan: isFromActionPlan,
        actionPlanId: actionPlanId,
        isFromReportForm: isFromReportForm,
        checklistViewTable,
        isSaveDisabled,
        setSaveDisabled,
        requiredSubType,
        requiredType
    } as IDataRowProps<any>


    if (!DataRow) return <h2>{t('GeneralizedDataRow.UNMANAGED_DATA_ROW_1')}</h2>;
    if (dataRowWrapper !== undefined) {
        return dataRowWrapper(dataRow, DataRow, index, props);
    }
    return <DataRow {...props} />
}

const _sortDataRows = (dataRows: IDataRow[]): IDataRow[] => {
    if (!dataRows) return [];
    return dataRows
        .map((dataRow, index) => {
            return {...dataRow, index}
        })
        .sort((a, b) => {
            if (a.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD) return 1
            if (b.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD) return -1
            return 0;
        });
}
const GeneralizedDataRow = (props: IGeneralizedDataRow) => {
    return <>{_sortDataRows(props.dataRows)
        .map((dataRow, index) =>
            <RenderDataRow
                key={dataRow.id}
                dataRow={dataRow}
                index={index}
                {...props}
            />)}
    </>
}


export default GeneralizedDataRow
