import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useGetCompanyUsersExtended, useGetEmploymentTypes } from "../../api/CompanyApi";
import { Icon } from "../../assets/Icons/IconClassNames";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import PaginatedDataTable from "../../components/DataTableComponents/PaginatedDataTable";
import When from "../../components/When";
import { CompanyRole } from "../../core/enums/enums";
import { UTCStringToLocaleString } from "../../core/helpers/dates/datesHelper";
import { ICompanyUserExtended } from "../../core/interfaces/company";
import useAccessCheck from "../../hooks/useAccessCheck";
import { useCNavigate } from "../../hooks/useCNavigate";
import EditCompanyUserModal from "../Company/EditCompanyUserModal";
import EditCompanyUserRole from "../Company/EditCompanyUserRole";
import NewCompanyUserModal from "../Company/NewCompanyUserModal";
import RemoveCompanyUserModal from "../Company/RemoveCompanyUserModal";
import styles from './Users.module.scss';
import { userRoleMap } from "./util";

const Users = () => {

    const {t} = useTranslation();
    const [users, loading] = useGetCompanyUsersExtended();
    const [checkAccess] = useAccessCheck();
    useGetEmploymentTypes();
    const navigate = useCNavigate();
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showViewMode, setShowViewMode] = useState(false);
    const [showEditRole, setShowEditRole] = useState(false);
    const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
    const [currentUser, setCurrentUser] = useState<ICompanyUserExtended | null>(null);
    const hide = () => {
        setCurrentUser(null)
        setShowEditUserModal(false)
        setShowRemoveUserModal(false)
        setShowViewMode(false);
        setShowEditRole(false);

    }

    const columns: TableColumn<ICompanyUserExtended>[] = [
        {
            name: t('Users.NAME_1'),
            selector: row => row.name,
            sortable: true,
        },
        {
            name: t('Users.POSITION_1'),
            selector: row => row.position ?? '-',
            sortable: true,
        },
        {
            name: t('Users.POSITION_LEVELS_1'),
            selector: row => row.positionLevels?.map(i => i.name)?.join(', '),
            sortable: true,
        },
        {
            name: t('Users.ROLE_1'),
            selector: row => t(userRoleMap[row.roleSymbol]),
            sortable: true,
        },
        {
            name: t('Users.EMAIL_1'),
            selector: row => row.email,
            sortable: true,
        },
        {
            name: t('Users.LAST_SEEN_1'),
            selector: cell => cell.lastActivity ? UTCStringToLocaleString(cell.lastActivity) : t('Users.NEVER_1'),
            sortable: true,
        },
        {
            name: <When condition={checkAccess(CompanyRole.ADMIN)}>
                <span className={styles.clickable} onClick={() => setShowNewUserModal(!showNewUserModal)}> {t("Users.ADD_USER_1")} </span>
            </When>,
            right: true,
            cell: row => (
                <CustomDropdown toggleType={DropdownToggleType.GEAR}>
                    <CustomDropdownItem
                        icon={Icon.EDIT}
                        text={t('Users.EDIT_1')}
                        onClick={() => {
                            setCurrentUser(row);
                            setShowEditUserModal(true);
                        }}
                    />
                    <CustomDropdownItem
                        icon={Icon.USER_PEN}
                        text={t('Users.CHANGE_ROLE_1')}
                        onClick={() => {
                            setCurrentUser(row);
                            setShowEditRole(true);
                        }}
                    />
                    <CustomDropdownItem
                        icon={Icon.REMOVE}
                        text={t('Users.DELETE_1')}
                        onClick={() => {
                            setCurrentUser(row);
                            setShowRemoveUserModal(true);
                        }}
                    />
                </CustomDropdown>
            )

        },
    ];

    const [filterText, setFilterText] = useState("");

    const filteredItems = users.filter(
        item => (
            (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.position && item.position.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.email && item.email.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.role && t(userRoleMap[item.roleSymbol]).toLowerCase().includes(filterText.toLowerCase())) ||
            (item.lastActivity && item.lastActivity.toLowerCase().includes(filterText))
        ),
    );

    if (!checkAccess(CompanyRole.ADMIN)) {
        navigate('/');
        return null;
    }
    return <MainContainer>
        <HeaderContainer title={t('Users.MANAGE_STAFF_1')}></HeaderContainer>
        <PaginatedDataTable
            data={filteredItems ?? []}
            progressPending={loading}
            columns={columns}
            withFilter
            filterText={filterText}
            setFilterText={(prev) => setFilterText(prev)}
            onRowClick={row => {
                navigate(row.id)
            }
            }/>
        <NewCompanyUserModal show={showNewUserModal} hide={() => setShowNewUserModal(false)}/>
        <EditCompanyUserModal currentUser={currentUser} hide={hide} show={showEditUserModal}/>
        <EditCompanyUserModal currentUser={currentUser} hide={hide} show={showViewMode} viewMode={true}/>
        <EditCompanyUserRole show={showEditRole} hide={hide} currentUser={currentUser}/>
        <RemoveCompanyUserModal currentUser={currentUser} hide={hide} show={showRemoveUserModal}/>
    </MainContainer>
}

export default Users
