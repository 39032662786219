import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import ProtectedRoutes from "../components/ProtectedRoutes";
import { useGetUser } from "../hooks/useAuth";
import AccessDenied from "../views/AccessDenied";
import ActionPlanView from "../views/ActionPlan/ActionPlanView";
import Companies from "../views/Company/Companies";
import Confirm from "../views/Confirm/Confirm";
import Dashboard from "../views/Dashboard/Dashboard";
import DashboardSuper from "../views/Dashboard/DashboardSuper";
import Document from "../views/Document/Document";
import DocumentDirectory from "../views/DocumentDirectory/DocumentsDirectory";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword";
import Incident from "../views/Incidents/Incident";
import Incidents from "../views/Incidents/Incidents";
import NewIncident from "../views/Incidents/NewIncident";
import Login from "../views/Login/Login";
import Missing from "../views/Missing";
import PositionLevels from "../views/PositionLevels/PositionLevels";
import Superadmins from "../views/Superadmins/Superadmins";
import Support from "../views/Support";
import TemplatesDirectory from "../views/Template/TemplatesDirectory";
import User from "../views/Users/User";
import UserDocuments from "../views/Users/UserDocuments";
import Users from "../views/Users/Users";
import { Role } from "./enums/enums";

const AppRoutes = () => {
    const {t} = useTranslation();
    const { cId } = useParams();
    const user = useGetUser();

    return <Routes>
        <Route path="/" element={<Layout/>}>
            {/* Public routes */}
            <Route path="login" element={<Login/>}/>
            <Route path="support" element={<Support/>}/>
            <Route path="restricted" element={<AccessDenied/>}/>
            <Route path="confirm" element={<Confirm/>}/>
            <Route path="forgot-password" element={<ForgotPassword/>}/>

            {/* Protected routes USER */}
            <Route element={<ProtectedRoutes allowedRoles={[Role.USER]}/>}>
                <Route path="/" element={<Navigate to={`c/${cId}`} replace/>}/>
                <Route path="c">
                    <Route index element={<Companies title={t('AppRoutes.CONNECTED_COMPANIES_1')} parentCompanyId={user.parentCompanyId} />} />
                    <Route path={":cId"}>
                        <Route index element={<Dashboard />} />
                        <Route path="templates">
                            <Route index element={<TemplatesDirectory companyId={user.companyId} />} />
                            <Route path=":documentId" element={<Document isFromTemplate={true} />} />
                        </Route>
                        <Route path="support" element={<Support/>}/>
                        <Route path="documents">
                            <Route index element={<DocumentDirectory/>}/>
                            <Route path=":documentId" element={<Document/>}/>
                        </Route>

                        <Route path="incidents">
                            <Route index element={<Incidents/>}/>
                            <Route path=":incidentId" element={<Incident/>}/>
                            <Route path="new" element={<NewIncident/>}/>
                        </Route>
                        <Route path="action-plan">
                            <Route index element={<ActionPlanView/>}/>
                        </Route>
                        <Route path="position-levels">
                            <Route index element={<PositionLevels />} />
                            <Route path=":positionLevelId" element={<PositionLevels />} />
                        </Route>
                        <Route path="users">
                            <Route index element={<Users/>}/>
                            <Route path={":cUId"}>
                                <Route index element={<User />} />
                                <Route path="documents" element={<UserDocuments />} />
                            </Route>
                        </Route>

                    </Route>

                </Route>

            </Route>

            {/* Protected routes SUPERADMIN */}
            <Route element={<ProtectedRoutes allowedRoles={[Role.SUPERADMIN]}/>}>
                <Route path="/">
                    <Route index element={<DashboardSuper/>}/>
                    <Route path="templates">
                        <Route index element={<TemplatesDirectory />} />
                        <Route path=":documentId" element={<Document isFromTemplate={true}/>}/>
                    </Route>
                    <Route path="companies">
                        <Route index element={<Companies title={t('AppRoutes.CUSTOMERS_SUPPLIERS_1')} />} />
                    </Route>
                    <Route path="superadmins">
                        <Route index element={<Superadmins/>}/>
                    </Route>
                </Route>
            </Route>

            {/* Catch all */}
            <Route path="*" element={<Missing/>}/>
        </Route>
    </Routes>
}

export default AppRoutes;
