import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import getIcon, { Icon, IconSize } from "../../../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../../../components/ClickableIconButton/ClickableIconButton";
import CustomTextInput from "../../../../components/FormComponents/CustomTextInput";
import When from "../../../../components/When";
import { DataRowType, DocumentTypes } from "../../../../core/enums/enums";
import { useDocumentModulesContext } from "../DocumentModules";
import { useChecklistContext } from "./ChecklistContext";

interface IChecklistModuleHeader {
    isActiveModule: boolean;
    header: string | undefined;
    setHeader: (value: string) => void;
    setChecklistToAnswer: () => void;
    setHideActionPlan: (value: boolean) => void;
    hideActionPlan: boolean;
    hideHeaderColumnTableView: boolean;
    setHideHeaderColumnTableView: (value: boolean) => void;
}

const ChecklistModuleHeader = (
    {
        isActiveModule,
        header,
        setHeader,
        hideActionPlan,
        setChecklistToAnswer,
        setHideActionPlan,
        hideHeaderColumnTableView,
        setHideHeaderColumnTableView,
    }: IChecklistModuleHeader) => {

    const {t} = useTranslation();

    const {
        document,
        isDocumentPreview,
        isEditMode,
        isDocumentClosed,
        isDocumentArchived,
        isAnswerMode,
        activeModule
    } = useDocumentModulesContext();

    const {
        isTableView,
    } = useChecklistContext();

    const [disableActionPlan, setDisableActionPlan] = useState(false);

    useEffect(() => {
        if (isActiveModule) {
            for (const activeForm of activeModule?.documentModuleChecklist?.documentModuleChecklistForms ?? []) {
                for (const row of activeForm.documentModuleChecklistFormDataRows) {
                    if (row.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD) {
                        setDisableActionPlan(true);
                        return;
                    }
                }
            }
            setDisableActionPlan(false);
        }
    }, [isActiveModule, activeModule])

    return <>
        <When
            condition={isEditMode}
            otherwise={<>
                <Row>

                    <Col className="pt-3 ms-3">
                        <When condition={!isAnswerMode}>
                            <h5><i className={`${getIcon(Icon.CHECK_CIRCLE)} ps-1 pe-2`}/>{header}</h5>
                        </When>
                    </Col>

                    <When
                        condition={!isDocumentPreview && !isAnswerMode && !isEditMode && !isDocumentClosed && !isDocumentArchived && DocumentTypes.TEMPLATE !== document?.documentType}>
                        <Col className={"me-2 pt-3 d-flex justify-content-end"}>
                            <ClickableIconButton
                                disabled={isEditMode}
                                buttonType={"EDIT"}
                                title={t('ChecklistModuleHeader.RESPOND_TO_CHECKLIST_1')}
                                icon={getIcon(Icon.EDIT, IconSize.XL)}
                                onClick={setChecklistToAnswer}
                            />
                        </Col>
                    </When>
                </Row>
            </>}
        >
            <Row className={"pt-1"}>
                <Col className="ms-2">
                    <span style={{ fontSize: "10px", textTransform: "uppercase" }}>{t('ChecklistModuleHeader.CHECKLIST_1')}</span>
                </Col>
            </Row>
            <Row>
                <Col xs={3} className="mt-3 ms-3">
                    <h5><strong>{t('ChecklistModuleHeader.HEADER_1')}</strong></h5>
                </Col>
                <Col className="mt-2 me-4">
                    <CustomTextInput
                        disabled={!isActiveModule}
                        isEditMode={isEditMode}
                        name={"header"}
                        value={header ?? ""}
                        onChange={(e) => setHeader(e.target.value)}
                        disableAutocomplete
                        required
                        showInputFieldInReadOnly
                    />
                </Col>
            </Row>
        </When>

        {isEditMode &&
            <Row className="mt-3">
                <Col xs={3} className={"ms-3"}>
                    {isActiveModule && isTableView && (
                        <Form.Check
                            checked={hideHeaderColumnTableView}
                            disabled={(!isActiveModule)}
                            type="switch"
                            id="switchHideHeaderColumnTableView"
                            label={t('ChecklistModuleHeader.HIDE_CHECKPOINT_COLUMN_1')}
                            onClick={(e: any) => {
                                setHideHeaderColumnTableView(e.target.checked)
                            }}
                        />
                    )}
                </Col>
                <Col className="ms-3">
                    <Form.Check
                        checked={hideActionPlan}
                        disabled={(!isEditMode || !isActiveModule) || disableActionPlan}
                        type="switch"
                        id="switchHideAllSendToActionPlan"
                        label={t('ChecklistModuleHeader.HIDE_1')}
                        onClick={(e: any) => {
                            setHideActionPlan(e.target.checked)
                        }}
                    />
                </Col>
            </Row>
        }
    </>
};

export default ChecklistModuleHeader;
