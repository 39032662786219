import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import When from "../../../../../components/When";
import { ChecklistView, DataRowType } from "../../../../../core/enums/enums";
import { IDataRow, IDataRowActionPlan, IDocumentModuleChecklistForm } from "../../../../../core/interfaces/document";


export function SendToActionPlan({
                                     setSaveState,
                                     handleAddDataRow,
                                     handleUpdateDataRows,
                                     form,
                                     isActive = true,
                                     setSendToActionPlan,
                                     viewType = ChecklistView.LIST,
                                     hidden
                                 }: {hidden: boolean, setSaveState: (state: boolean) => void, handleAddDataRow: (row: IDataRow) => void, handleUpdateDataRows: (rows: IDataRow[]) => void, setSendToActionPlan: (v: boolean) => void, form: IDocumentModuleChecklistForm, isActive?: boolean, viewType?: ChecklistView }) {


    const {t} = useTranslation();
    // const actionRow = form.documentModuleChecklistFormDataRows.filter(v => v.checklistFormDataRowTypeSymbol !== DataRowType.ACTION_PLAN_FIELD)[0];
    const randomNumber = Math.random();

    return <When condition={!hidden}>
        <Row className={viewType === ChecklistView.LIST ? "mt-3 mb-3 ms-2 me-2" : ""}>
            <Col md={viewType === ChecklistView.LIST ? 3 : 12}>
                <Form.Check
                    disabled={!isActive}
                    type="switch"
                    id={`switchSendToActionPlan_${randomNumber}`}
                    checked={form.sendToActionPlan}
                    label={viewType === ChecklistView.LIST ? t('SendToActionPlan.ACTION_PLAN_UPON_DOCUMENT_COMPLETION_1') : t('SendToActionPlan.ACTION_PLAN_1')}
                    onClick={(v: any) => {
                        if (v.target.checked) {
                            handleAddDataRow({
                                id: "Handle_Action_Row",
                                isNew: true,
                                header: t('SendToActionPlan.RESPONSIBLE_FOR_ACTION_1'),
                                checklistFormDataRowTypeSymbol: DataRowType.ACTION_PLAN_FIELD
                            } as IDataRowActionPlan)
                        } else {
                            handleUpdateDataRows([...form.documentModuleChecklistFormDataRows.filter(v => v.checklistFormDataRowTypeSymbol !== DataRowType.ACTION_PLAN_FIELD)]);
                        }
                        setSendToActionPlan(v.target.checked);
                    }}
                />
            </Col>
        </Row>

    </When>;
}
