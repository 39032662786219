import { CompanyRole, EmploymentType } from "../../core/enums/enums";


export const employmentTypeMap: Record<EmploymentType, string> = {
    [EmploymentType.NOT_SPECIFIED]: 'util.NOT_SPECIFIED_1',
    [EmploymentType.NOT_EMPLOYED]: 'util.NOT_EMPLOYED_1',
    [EmploymentType.HIRED]: 'util.HIRED_1',
    [EmploymentType.CONSULTANT]: 'util.CONSULTANT_1',
    [EmploymentType.APPRENTICE]: 'util.APPRENTICE_1',
    [EmploymentType.INTERNSHIP]: 'util.WORK_EXPERIENCE_1',
    [EmploymentType.PROJECT_EMPLOYMENT]: 'util.PROJECT_EMPLOYMENT_1',
    [EmploymentType.PROBATIONARY_EMPLOYMENT]: 'util.TRIAL_EMPLOYMENT_1',
    [EmploymentType.SEASONAL_WORKER]: 'util.SEASONAL_EMPLOYMENT_1',
    [EmploymentType.PERMANENT]: 'util.UNTIL_FURTHER_NOTICE_1',
    [EmploymentType.HOURLY_EMPLOYEE]: 'util.PART_TIME_EMPLOYEE_1',
    [EmploymentType.SUBSTITUTE]: 'util.SUBSTITUTE_1',
    [EmploymentType.FIXED_TERM_EMPLOYMENT]: 'util.FIXED_TERM_EMPLOYMENT_1'
};

export const userRoleMap: Record<CompanyRole, string> = {
    [CompanyRole.ADMIN]: 'User.ADMIN_1',
    [CompanyRole.EDITOR]: 'User.EDITOR_1',
    [CompanyRole.READER]: 'User.READER_1',
}

export const getBlockedFileFormats = [".exe", ".com", ".bat", ".dll", ".cmd", ".msi", ".vb", ".vbs", ".ws", ".wsf", ".scf", ".scr", ".pif"];
export const getBlockedFileTypes = [ "application/x-msdownload", "application/octet-stream", "application/bat", "application/cmd", "application/exe", "application/dll", "application/vbscript", "application/vbs", "application/scriptlet", "application/wsf", "application/pif"];
