import { Icon } from "../../../../../../assets/Icons/IconClassNames";
import {
    DataRowType,
    MultiChoiceAlternativeClassified,
    MultiChoiceDisplayType
} from "../../../../../../core/enums/enums";
import {
    IDataRow,
    IDataRowActionPlan,
    IDataRowAssessmentScopeSeverityField,
    IDataRowFileField,
    IDataRowImageField,
    IDataRowMultiChoiceField,
    IDataRowNumericField,
    IDataRowRiskAssessmentField,
    IDataRowSignatureField,
    IDataRowTextField,
    IDataRowUserAndDate,
    IDateDataRow,
    IMultiPickDataRow,
    IMultiUserDataRow,
    IUserDataRow
} from "../../../../../../core/interfaces/document";



export const PossibleDataRows: DataRowType[] = [
    DataRowType.TEXT_FIELD,
    DataRowType.NUMERIC_FIELD,
    DataRowType.SIGNATURE_FIELD,
    DataRowType.RISK_ASSESSMENT_FIELD,
    DataRowType.MULTI_CHOICE_FIELD,
    DataRowType.IMAGE_FIELD,
    DataRowType.DATE_FIELD,
    DataRowType.USER_FIELD,
    DataRowType.FILES_FIELD,
    DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD,
    DataRowType.USER_AND_DATE_FIELD
];
export const DataRowTypeIcons: Record<DataRowType, Icon> = {
    [DataRowType.TEXT_FIELD]: Icon.TEXT_FORMAT_BOLD,
    [DataRowType.NUMERIC_FIELD]: Icon.CUBES,
    [DataRowType.SIGNATURE_FIELD]: Icon.USER_PEN,
    [DataRowType.RISK_ASSESSMENT_FIELD]: Icon.WARNING,
    [DataRowType.MULTI_CHOICE_FIELD]: Icon.BARS,
    [DataRowType.ACTION_PLAN_FIELD]: Icon.CHECK_CIRCLE,
    [DataRowType.IMAGE_FIELD]: Icon.IMAGE,
    [DataRowType.MULTI_USER_FIELD]: Icon.CUSTOMERS,
    [DataRowType.MULTI_PICK_FIELD]: Icon.GRIP_LINES,
    [DataRowType.DATE_FIELD]: Icon.CALENDAR,
    [DataRowType.USER_FIELD]: Icon.USER_PEN,
    [DataRowType.FILES_FIELD]: Icon.FILE_IMPORT,
    [DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD]: Icon.WARNING,
    [DataRowType.USER_AND_DATE_FIELD]: Icon.USER_CLOCK,
};
export const DataRowTypeNameTranslation: Record<DataRowType, string> = {
    [DataRowType.TEXT_FIELD]: 'DataRowGeneration.TEXT_1',
    [DataRowType.NUMERIC_FIELD]: 'DataRowGeneration.NUMERICAL_1',
    [DataRowType.SIGNATURE_FIELD]: 'DataRowGeneration.SIGNATURE_1',
    [DataRowType.RISK_ASSESSMENT_FIELD]: 'DataRowGeneration.RISK_ASSESSMENT_1',
    [DataRowType.MULTI_CHOICE_FIELD]: 'DataRowGeneration.MULTIPLE_CHOICE_1',
    [DataRowType.ACTION_PLAN_FIELD]: 'DataRowGeneration.ACTION_1',
    [DataRowType.IMAGE_FIELD]: 'DataRowGeneration.PICTURE_1',
    [DataRowType.MULTI_USER_FIELD]: 'DataRowGeneration.SEVERAL_USERS_1',
    [DataRowType.MULTI_PICK_FIELD]: 'DataRowGeneration.MULTIPLE_CHOICES_1',
    [DataRowType.DATE_FIELD]: 'DataRowGeneration.DATE_1',
    [DataRowType.USER_FIELD]: 'DataRowGeneration.USER_1',
    [DataRowType.FILES_FIELD]: 'DataRowGeneration.FILES_1',
    [DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD]: 'DataRowGeneration.SCOPE_SEVERITY_1',
    [DataRowType.USER_AND_DATE_FIELD]: 'DataRowGeneration.USERS_AND_DATE_1',
};

export const dataRowTypeToGeneratorMap: Record<DataRowType, () => IDataRow> = {
    [DataRowType.TEXT_FIELD]: generateDataRowTextField,
    [DataRowType.NUMERIC_FIELD]: generateDataRowNumericField,
    [DataRowType.SIGNATURE_FIELD]: generateDataRowSignatureField,
    [DataRowType.RISK_ASSESSMENT_FIELD]: generateDataRowRiskAssessmentField,
    [DataRowType.MULTI_CHOICE_FIELD]: generateDataRowMultiChoiceField,
    [DataRowType.ACTION_PLAN_FIELD]: generateDataRowActionPlan,
    [DataRowType.DATE_FIELD]: generateDateDataRow,
    [DataRowType.MULTI_USER_FIELD]: generateMultiUserDataRow,
    [DataRowType.MULTI_PICK_FIELD]: generateMultiPickDataRow,
    [DataRowType.IMAGE_FIELD]: generateDataRowImageField,
    [DataRowType.USER_FIELD]: generateUserDataRow,
    [DataRowType.FILES_FIELD]: generateFileDataRow,
    [DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD]: generateDataRowAssessmentScopeSeverityField,
    [DataRowType.USER_AND_DATE_FIELD]: generateUserAndDateDataRow,
};

function generateDataRowTextField(): IDataRowTextField {
    return {
        id: "1",
        checklistFormDataRowTypeSymbol: DataRowType.TEXT_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        textValue: "",
    };
}

function generateDataRowNumericField(): IDataRowNumericField {
    return {
        id: "2",
        checklistFormDataRowTypeSymbol: DataRowType.NUMERIC_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        isMinMaxChecked: true,
        numValue: 10,
        numMinValue: 5,
        numMaxValue: 20,
    };
}

function generateDataRowSignatureField(): IDataRowSignatureField {
    return {
        id: "3",
        checklistFormDataRowTypeSymbol: DataRowType.SIGNATURE_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        signatureUserId: null,
        signatureName: undefined,
        signatureDate: null,
    };
}

function generateDataRowRiskAssessmentField(): IDataRowRiskAssessmentField {
    return {
        id: "4",
        checklistFormDataRowTypeSymbol: DataRowType.RISK_ASSESSMENT_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
    };
}

function generateDataRowMultiChoiceField(): IDataRowMultiChoiceField {
    return {
        id: "5",
        checklistFormDataRowTypeSymbol: DataRowType.MULTI_CHOICE_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        multiChoiceDisplayType: MultiChoiceDisplayType.DROPDOWN,
        multiChoiceValue: 'DataRowGeneration.OPTION_ONE_1',
        multiChoiceValueClassified: MultiChoiceAlternativeClassified.NEUTRAL,
        multiChoiceAlternatives: [
            {
                name: 'DataRowGeneration.OPTION_ONE_1',
                classifiedAs: MultiChoiceAlternativeClassified.NEGATIVE,
            },
            {
                name: 'DataRowGeneration.OPTION_TWO_1',
                classifiedAs: MultiChoiceAlternativeClassified.POSITIVE,
            },
        ],
    };
}

function generateDataRowActionPlan(): IDataRowActionPlan {
    return {
        id: "6",
        checklistFormDataRowTypeSymbol: DataRowType.ACTION_PLAN_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        actionUserId: "456",
        actionDate: null,
    };
}

function generateDateDataRow(): IDateDataRow {
    return {
        id: "7",
        checklistFormDataRowTypeSymbol: DataRowType.DATE_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        date: null,
        dateOnly: false,
    };
}

function generateMultiUserDataRow(): IMultiUserDataRow {
    return {
        id: "8",
        checklistFormDataRowTypeSymbol: DataRowType.MULTI_USER_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        multiUsers: [],
    };
}

function generateMultiPickDataRow(): IMultiPickDataRow {
    return {
        id: "9",
        checklistFormDataRowTypeSymbol: DataRowType.MULTI_PICK_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        multiPicks: [
            {name: 'DataRowGeneration.OPTION_ONE_1', chosen: true},
            {name: 'DataRowGeneration.OPTION_TWO_1', chosen: false},
            {name: "DataRowGeneration.OPTION_THREE_1", chosen: true},
        ],
    };
}

function generateDataRowImageField(): IDataRowImageField {
    return {
        id: "",
        checklistFormDataRowTypeSymbol: DataRowType.IMAGE_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        imageFile: "",
        imageName: "",
        imageContentType: "",
    };
}

function generateUserDataRow(): IUserDataRow {
    return {
        id: "11",
        checklistFormDataRowTypeSymbol: DataRowType.USER_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        userId: null,
    };
}

function generateFileDataRow(): IDataRowFileField {
    return {
        id: "",
        checklistFormDataRowTypeSymbol: DataRowType.FILES_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        files: [],
        newFiles: [],
    };
}

function generateDataRowAssessmentScopeSeverityField(): IDataRowAssessmentScopeSeverityField {
    return {
        id: "13",
        checklistFormDataRowTypeSymbol: DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
    };
}

function generateUserAndDateDataRow(): IDataRowUserAndDate {
    return {
        id: "14",
        checklistFormDataRowTypeSymbol: DataRowType.USER_AND_DATE_FIELD,
        header: "",
        isNew: true,
        isClosed: false,
        userAndDateDate: null,
        userAndDateUserId: null
    };
}
