import i18n from "i18next";


const format = (date: number) => {
    if (date < 10) return `0${date}`
    return date + '';
}
export const DateToYYYYMMDD = (date: string | Date | undefined): string => {
    if (!date) return "";
    try {
        const tempDate = new Date(date);
        return `${tempDate.getFullYear()}-${format(tempDate.getMonth() + 1)}-${format(tempDate.getDate())}`;
    } catch (error: any) {
        console.error(error);
        return "";
    }
}

export const DateToHHMM = (date: string | Date | undefined): string => {
    if (!date) return "";
    try {
        return new Date(date).toTimeString().substring(0, 5);
    } catch (error: any) {
        console.error(error);
        return "";
    }
}

export const DateToYYYYMMDDHHMM = (date: string | Date | undefined): string => {
    if (!date) return "";
    try {
        return DateToYYYYMMDD(date) + " " + DateToHHMM(date);
    } catch (error: any) {
        console.error(error);
        return "";
    }
}


export const DateToLocale = (date?: string): string => {
    if (!date) return "";
    const tempDate = new Date(date);

    return new Date(Date.UTC(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), tempDate.getHours(), tempDate.getMinutes(), tempDate.getMilliseconds())).toLocaleString();
}

export const DateToUTC = (date?: string): string => {
    if (!date) return "";
    return new Date(date).toISOString();
}

export const UTCStringToLocaleString = (date: string, type?: "date" | "time") => {
    const utcDate = new Date(date);

    const optionsDateOnly: Intl.DateTimeFormatOptions = {
        timeZone: i18n.t('datesHelper.LOCATION'),
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };

    const optionsTimeOnly: Intl.DateTimeFormatOptions = {
        timeZone: i18n.t('datesHelper.LOCATION'),
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    };

    const optionsFull: Intl.DateTimeFormatOptions = {
        timeZone: i18n.t('datesHelper.LOCATION'),
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    if (type === "date") {
        return utcDate.toLocaleDateString('sv-SE', optionsDateOnly);
    } else if (type === "time") {
        return utcDate.toLocaleTimeString('sv-SE', optionsTimeOnly);
    }
    return utcDate.toLocaleString('sv-SE', optionsFull);
}

export const RegexValidateDate = (date: string): boolean => {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
    if (regex.test(date)) {
        return true;
    }
    return false;
}

// export const GetCompleteDate = (localInputDate: any, internalDate: string | null | undefined): Date | null | undefined => {
//
//     if (!localInputDate || localInputDate.length < 10 || localInputDate.length > 15) return;
//
//     const testDate = new Date(localInputDate);
//     if (isNaN(testDate.getTime())) return;
//
//     let time = "";
//     if (internalDate && internalDate.length > 10) {
//         time = internalDate.substring(10);
//     } else {
//         time = " 00:00:00";
//     }
//
//     const aggDate = localInputDate + time;
//
//     const localDate = new Date(aggDate);
//
//     return localDate;
//
// }
